import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HTMLReactParser from "html-react-parser"
import { getValueD9, getDescription } from "../utils"
import SocialSharing from "../components/SocialSharing"
import Documents from "../components/Documents"
const PageTemplate = ({ data, ...props }) => {
  const body = getValueD9(data.nodeSsPage, "body.processed", "")
  return (
    <Layout title={data.nodeSsPage.title} {...props} description={getDescription(body)}>
      <div className="uk-section uk-padding uk-background-default">
        {HTMLReactParser(body)}
        <SocialSharing />
        <Documents data={getValueD9(data.nodeSsPage, "relationships.field_files", [])} />
      </div>
    </Layout>
  )
}
export default PageTemplate

export const query = graphql`
  query Page_ByID($id: String!) {
    nodeSsPage(id: { eq: $id }) {
      title
      body {
        processed
      }
      relationships {
        field_files {
          relationships {
            field_media_document {
              filename
              uri {
                url
                value
              }
            }
          }
        }
      }
    }
  }
`
