import React from "react"
import { getValueD9, renameImage } from "../utils"

const Documents = ({ data }) => {
  return data.length > 0 ? (
    <div className="uk-flex uk-flex-left uk-flex-column">
      <p className="">Documents associés :</p>
      <ul className="uk-margin-remove uk-flex uk-flex-left uk-list uk-flex-column">
        {data.map((doc, i) => {
          const document = getValueD9(doc, "relationships.field_media_document")
          return (
            <li className="" key={document.filename}>
              <a
                className="uk-button uk-button-primary "
                href={renameImage(document.uri.value)}
                target="_blank"
              >
                <span uk-icon="icon: file-text" className=" uk-margin-small-right"></span>
                {document.filename}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  ) : null
}

export default Documents
